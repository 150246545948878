import { useTranslation } from 'next-i18next'
import Carousel from 'react-multi-carousel'

import SectionLayout from '../../commons/layouts/SectionLayout'
import ReviewCard from './ReviewCard'

import styles from './ReviewSection.module.scss'

import { responsive } from 'lib/checkBreakpoint'
import userReviews from '../../../data/user_reviews.json'

const ReviewSection = () => {
  const { t, i18n } = useTranslation()

  return (
    <SectionLayout
      sectionName="review"
      sectionImageAlt={t('META.REVIEW')}
      title={t('COMMON.REVIEW_LABEL')}
      style={styles.section}
    >
      <Carousel
        ssr
        responsive={responsive}
        infinite
        swipeable
        className={styles.reviewItems}
        customLeftArrow={<div className={styles.prev} />}
        customRightArrow={<div className={styles.next} />}
      >
        {userReviews[i18n.language === 'zh-TW' ? 'en' : i18n.language].map((review) => (
          <ReviewCard review={review} key={review.id} />
        ))}
      </Carousel>
    </SectionLayout>
  )
}

export default ReviewSection
